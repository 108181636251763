import { css, keyframes } from '@emotion/react'

export const spinningAnimation = keyframes`
to{ transform: rotate(360deg)}`

export const container: Style = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
})

export const spinner: Style = ({ color, backgroundColor }) => ({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  background: 'transparent',
  borderTop: `4px solid ${color.primary}`,
  borderRight: `4px solid ${backgroundColor.secondary}`,
  borderBottom: `4px solid ${color.primary}`,
  borderLeft: `4px solid ${backgroundColor.secondary}`,
  animation: `${spinningAnimation} 1.7s infinite linear`,
})

export const compact: Style = css({
  padding: 0,
  width: '16px',
  height: '16px',
})

// todo: pick a new loader: https://css-loaders.com/dots/
