import * as styles from './LoadingSpinner.styles'

interface Props {
  compact?: boolean
}

const LoadingSpinner: React.FC<Props> = ({ compact }) => {
  return (
    <div css={styles.container}>
      <div css={[styles.spinner, compact && styles.compact]} />
    </div>
  )
}

export default LoadingSpinner
